


































import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import TableComponent from "@/components/TableComponent.vue";
import i18n, { KEYS } from "@/plugins/i18n";
import { playersService } from "@/rest/players.service";
import PlayerPaidExcelColumnSelectionComponent from "@/components/PlayerPaidExcelColumnSelectionComponent.vue";
import CheckPaymentStatusRequestDto, {
  ExcelColumDefinition,
} from "@/dtos/CheckPaymentStatusRequestDto";
import { ExcelUtils } from "@/utils/ExcelUtils";

@Component({
  components: { PlayerPaidExcelColumnSelectionComponent, TableComponent },
})
export default class CheckExcelPlayerPaymentComponent extends Vue {
  @Prop()
  private jsonData: string[][] | null = null;

  private showColumnChooseDialog = false;

  private rows: string[][] | null = null;
  private sDownload = i18n.t(KEYS.common.download).toString();
  private sYes = i18n.t(KEYS.common.yes).toString();
  private sNo = i18n.t(KEYS.common.no).toString();

  private onExcelColumnsChosen(columnSelection: ExcelColumDefinition) {
    this.showColumnChooseDialog = false;
    this.parseExcel(columnSelection);
  }

  private get headers(): string[] {
    if (!this.jsonData) {
      return [];
    }
    const result = [...this.jsonData[0]];
    result.push(i18n.t(KEYS.payments.paid).toString());

    return result;
  }

  private downloadExcel() {
    if (
      !this.headers ||
      this.headers.length == 0 ||
      !this.rows ||
      this.rows.length == 0
    ) {
      return;
    }
    const excelRows = this.rows.map((row) => {
      const excelRow: { [k: string]: string } = {};
      this.headers.forEach((header, col) => {
        excelRow[header] = row[col];
      });
      return excelRow;
    });

    ExcelUtils.exportExcel(excelRows, "Zahlungen");
  }

  @Watch("jsonData")
  private async jsonDataChange(): Promise<void> {
    if (!this.jsonData) {
      return;
    }

    this.showColumnChooseDialog = true;
  }

  private async parseExcel(
    columnSelection: ExcelColumDefinition
  ): Promise<void> {
    if (!this.jsonData) {
      return;
    }

    const newArray = [...this.jsonData];
    const headers = this.headers;
    newArray.shift();
    newArray.map((row) => {
      row.length = headers.length;

      return Array.from(row, (v) => v ?? ""); // pad with empty string
    });
    const rows = Array.from(newArray, (v) => v ?? ""); // add all columns based on the header

    const requestDtos: CheckPaymentStatusRequestDto[] = this.jsonData
      .slice(1)
      .map((row, rowIndex) => {
        return CheckPaymentStatusRequestDto.fromStringArray(
          rowIndex,
          row,
          columnSelection
        );
      });

    const paymentStatuses = await playersService.getPaymentStatus(requestDtos);

    for (let paymentStatus of paymentStatuses) {
      const row = rows[paymentStatus.rowNumber];
      row[row.length - 1] = paymentStatus.hasPaid ? this.sYes : this.sNo;
    }
    this.rows = rows;
  }
}
