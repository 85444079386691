




























import Vue from "vue";
import Component from "vue-class-component";
import { KEYS } from "@/plugins/i18n";
import ExcelColumnSelectionCheckbox from "@/components/ExcelColumnSelectionCheckbox.vue";
import { Emit } from "vue-property-decorator";
import { ExcelColumDefinition } from "@/dtos/CheckPaymentStatusRequestDto";

@Component({
  components: { ExcelColumnSelectionCheckbox },
})
export default class PlayerPaidExcelColumnSelectionComponent extends Vue {
  private readonly sColumn = this.$t(KEYS.common.columns).toString();
  private readonly sOedgvNo = this.$t(KEYS.players.playerNumber).toString();
  private readonly sPdgaNo = this.$t(KEYS.players.pdgaNumber).toString();
  private readonly sFirstName = this.$t(KEYS.players.firstName).toString();
  private readonly sLastName = this.$t(KEYS.players.lastName).toString();
  private readonly sOk = this.$t(KEYS.common.ok).toString();

  result: ExcelColumDefinition = new ExcelColumDefinition();

  @Emit("on-apply")
  private applyClicked(): ExcelColumDefinition {
    const tempResult = this.result;
    this.result = new ExcelColumDefinition();
    return tempResult;
  }
}
