var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.value,staticClass:"header"},[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showPaymentsAction)?_c('v-icon',{staticClass:"action-icon",on:{"click":function($event){return _vm.onShowPayments(item)}}},[_vm._v(" mdi-account-cash ")]):_vm._e(),(_vm.showEditAction)?_c('v-icon',{staticClass:"action-icon",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.deleteTitle != '')?_c('v-icon',{staticClass:"action-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteClick(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},_vm._l((_vm.headersWithCustomFormatter(_vm.headers)),function(header){return {key:("item." + (header.value)),fn:function(ref){
var header = ref.header;
var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showAreYouSureDeleteDialog),callback:function ($$v) {_vm.showAreYouSureDeleteDialog=$$v},expression:"showAreYouSureDeleteDialog"}},[_c('are-you-sure-dialog-component',{attrs:{"header":_vm.deleteTitle},on:{"canceled":function () { return (this$1.showAreYouSureDeleteDialog = false); },"confirmed":_vm.onDelete}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }