import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import PlayerDto from "@/dtos/PlayerDto";

export interface ShowDialogData {
  isShowing: boolean;
  playerDto: PlayerDto | null;
}

@Module({ namespaced: true })
export class PlayerDialogModule extends VuexModule {
  private _isShowing = false;
  private _data: PlayerDto | null = null;

  @Mutation
  public show(data: ShowDialogData): void {
    this._isShowing = data.isShowing;
    if (data.isShowing) {
      this._data = data.playerDto || new PlayerDto();
    } else {
      this._data = null;
    }
  }

  public get isShowing(): boolean {
    return this._isShowing;
  }

  @Mutation
  public hide(): void {
    this._isShowing = false;
  }

  public get playerDto(): PlayerDto | null {
    return this._data;
  }
}
