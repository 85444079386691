import i18n, { KEYS } from "@/plugins/i18n";

enum FederateStates {
  Vienna = 0,
  LowerAustria = 1,
  UpperAustria = 2,
  Burgenland = 3,
  Styria = 4,
  Salzburg = 5,
  Carinthia = 6,
  Tyrol = 7,
  Vorarlberg = 8,
}

export interface FederalStateItem {
  text: string;
  value: FederateStates;
}

export function getText(federateState: string): string {
  const key = KEYS.federateStates.unknown.replace("unknown", federateState);
  return i18n.t(key).toString();
}

export function getFederalStateItems(): FederalStateItem[] {
  return Object.entries(FederateStates)
    .filter(([name, value]) => name && typeof value === "number")
    .map<FederalStateItem>(([name, value]) => {
      return { text: getText(name), value } as FederalStateItem;
    })
    .sort((item1, item2) => (item1.text < item2.text ? -1 : 1));
}

export default FederateStates;
