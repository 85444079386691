





























































































































































































import Component from "vue-class-component";
import Vue from "vue";
import { KEYS } from "@/plugins/i18n";
import { Auth, PlayerDetails } from "@/store/namespaces";
import { Ref, Watch } from "vue-property-decorator";
import InstructorLevels from "@/dtos/InstructorLevel";
import PlayerDto from "@/dtos/PlayerDto";
import ClubDto from "@/dtos/ClubDto";
import { clubsService } from "@/rest/clubs.service";
import { playersService } from "@/rest/players.service";
import { AxiosError } from "axios";
import { genderSelectItems, SelectItem } from "@/dtos/Gender";

type InstructorLeveEntry = { value: number | null; text: string };

@Component
export default class PlayerDetailsComponent extends Vue {
  private isValid = false;
  private l_birthday = this.$t(KEYS.players.birthday) + " *";
  private l_firstName = this.$t(KEYS.players.firstName).toString() + " *";
  private l_lastName = this.$t(KEYS.players.lastName).toString() + " *";
  private l_gender = this.$t(KEYS.players.gender).toString() + " *";
  private l_street = this.$t(KEYS.players.street).toString() + " *";
  private l_streetNumber = this.$t(KEYS.players.streetNumber).toString() + " *";
  private l_zipCode = this.$t(KEYS.players.zipCode).toString() + " *";
  private l_city = this.$t(KEYS.players.city).toString() + " *";
  private l_email = this.$t(KEYS.users.email).toString() + " *";
  private l_phone = this.$t(KEYS.players.phone).toString();
  private l_country = this.$t(KEYS.players.country).toString() + " *";
  private l_instructorLevel = this.$t(KEYS.players.instructorLevel).toString();
  private l_wannaBeContacted = this.$t(
    KEYS.players.wannaBeContactedAsInstructor
  ).toString();
  private l_pdgaNumber = this.$t(KEYS.players.pdgaNumber).toString();
  private l_oedgvNumber = this.$t(KEYS.players.playerNumber).toString();
  private l_primaryClub = this.$t(KEYS.players.primaryClub).toString() + " *";
  private l_youthPlayer = this.$t(KEYS.players.youthPlayer).toString();

  private s_save = this.$t(KEYS.common.save).toString();
  private s_cancel = this.$t(KEYS.common.cancel).toString();
  private s_valueRequired = this.$t(KEYS.error.value_required).toString();

  private errorMessage: string | null = null;

  @PlayerDetails.Getter
  private isShowing!: boolean;
  @PlayerDetails.Mutation
  private hide!: () => void;

  @Auth.Getter
  private isDachverbandUser!: boolean;

  @Auth.Getter
  private isClubManager!: boolean;

  @Auth.Getter
  private authenticatedClubId!: string;

  @Ref()
  private form!: HTMLFormElement;

  @Ref("mainClubSelect")
  private mainClubSelect!: HTMLSelectElement;

  private clubs: ClubDto[] = [];

  private get isReadOnly() {
    return (
      !this.isCreate &&
      !this.isDachverbandUser &&
      this.playerDto.mainClubId !== this.authenticatedClubId
    );
  }

  private get showBirthday(): boolean {
    return this.isDachverbandUser || this.isClubManager;
  }

  @PlayerDetails.Getter
  private playerDto!: PlayerDto;

  get genderItems(): Array<SelectItem> {
    return genderSelectItems();
  }

  get _isShowing(): boolean {
    return this.isShowing;
  }

  set _isShowing(val: boolean) {
    if (!val) {
      this.hide();
    }
  }

  private get isCreate(): boolean {
    return !this.playerDto?.id;
  }

  get birthdayValueString(): string | null {
    return this.playerDto?.birthday?.toISOString()?.substr(0, 10) || null;
  }

  set birthdayValueString(strValue: string | null) {
    if (strValue == null) {
      this.playerDto.birthday = null;
    } else {
      this.playerDto.birthday = new Date(strValue);
    }
  }

  get instructorLevelEnum(): InstructorLeveEntry[] {
    let result: InstructorLeveEntry[] = [
      { value: null, text: this.$t(KEYS.instructorLevels.none).toString() },
    ];
    for (let level in InstructorLevels) {
      const entry: InstructorLeveEntry =
        InstructorLevels[level as keyof typeof InstructorLevels];
      result.push(Object.freeze(entry));
    }

    return result;
  }

  async mounted(): Promise<void> {
    if (!this.isDachverbandUser && this.isCreate) {
      this.playerDto.mainClubId = this.authenticatedClubId;
    }

    this.clubs = await clubsService.getAll();
    if (!this.isCreate && this.mainClubSelect) {
      this.mainClubSelect.textContent = this.clubs.filter(
        (c) => c.id === this.mainClubSelect.value
      )[0].name;
    }
  }

  get heading(): string {
    if (this.isCreate) {
      return this.$t(KEYS.headings.create_player).toString();
    } else {
      return this.$t(KEYS.headings.edit_player).toString();
    }
  }

  async submit(): Promise<void> {
    this.errorMessage = null;
    try {
      if (!this.playerDto.mainClubId) {
        this.playerDto.mainClubId = this.authenticatedClubId;
      }
      if (this.isCreate) {
        await playersService.createPlayer(this.playerDto);
      } else {
        await playersService.update(this.playerDto);
      }
      this.hide();
    } catch (e) {
      const axiosError = e as AxiosError;
      this.errorMessage = axiosError.response?.data?.toString() || e.message;
    }
  }

  @Watch("isShowing")
  onIsShowingChange(newVal: boolean): void {
    if (newVal) {
      this.errorMessage = "";
      if (this.isCreate) {
        if (this.form) {
          this.form.reset();
        }
        if (this.playerDto) {
          this.playerDto.wannaBeContacted = false;
        }
      }
    }
  }
}
