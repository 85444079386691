import GetUserListDto from "@/dtos/GetUserListDto";

export default interface CreateUserDto {
  clubId: string | null;
  username: string | null;
  password: string | null;
  email: string;
  isTournamentManager: boolean;
  isDachverbandUser: boolean;
  isClubManager: boolean;
}

export function EmptyCreateUserDto(): CreateUserDto {
  return {
    clubId: null,
    password: null,
    username: null,
    email: "",
    isClubManager: false,
    isDachverbandUser: false,
    isTournamentManager: false,
  };
}

export function CreateUserDtoFromGetUserListDto(
  dto: GetUserListDto | null,
  password: string
): CreateUserDto {
  if (dto == null) {
    return EmptyCreateUserDto();
  }

  return {
    clubId: dto.club?.id || null,
    isTournamentManager: dto.isTournamentManager,
    isDachverbandUser: dto.isDachverbandUser,
    isClubManager: dto.isClubManager,
    username: dto.username,
    password: password,
    email: dto.email,
  };
}
