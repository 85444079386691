import FederateStates from "@/dtos/FederateStates";

export default interface ClubDto {
  id: string | null;
  name: string | null;
  username: string | null;
  password: string | null;
  email: string | null;
  federateState: FederateStates | null;
  city: string | null;
}

export function emptyClubDto(): ClubDto {
  return {
    id: null,
    name: null,
    username: null,
    password: null,
    email: null,
    federateState: null,
    city: null,
  };
}
