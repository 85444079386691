import axios, { AxiosError } from "axios";
import AuthResultDto from "@/dtos/AuthResultDto";
import UserAuthDto from "@/dtos/UserAuthDto";
import RefreshTokenRequestDto from "@/dtos/RefreshTokenRequestDto";
import i18n, { KEYS } from "@/plugins/i18n";

enum ErrorCode {
  LOCKED = "LOCKED",
}

function getErrorMessage(code: ErrorCode) {
  switch (code) {
    case ErrorCode.LOCKED:
      return i18n.t(KEYS.error.user_is_locked).toString();
  }
}

class AuthService {
  private readonly path = "api/auth";
  public readonly loginUrl = this.path + "/login";
  public readonly logoutUrl = `${this.path}/logout`;
  public readonly refreshUrl = `${this.path}/refresh`;
  public readonly changePwUrl = `${this.path}/changepw`;

  async login(userAuthDto: UserAuthDto) {
    try {
      const response = await axios.post<AuthResultDto>(
        this.loginUrl,
        userAuthDto
      );
      return response.data;
    } catch (e: unknown) {
      const axiosError = e as AxiosError;
      if (axiosError?.response?.data == ErrorCode.LOCKED) {
        throw new Error(getErrorMessage(ErrorCode.LOCKED));
      } else {
        throw new Error(i18n.t(KEYS.error.invalidUsernamePassword).toString());
      }
    }
  }

  async logout(): Promise<void> {
    await axios.post(this.logoutUrl);
  }

  async refresh(
    refreshTokenRequestDto: RefreshTokenRequestDto
  ): Promise<AuthResultDto> {
    const response = await axios.post<AuthResultDto>(
      this.refreshUrl,
      refreshTokenRequestDto
    );
    return response.data;
  }

  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<AuthResultDto> {
    const response = await axios.post<AuthResultDto>(this.changePwUrl, {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
    return response.data;
  }
}

export const authService = new AuthService();
