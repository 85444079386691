








import { Component, Vue } from "vue-property-decorator";
import CreateDachverbandUserComponent from "@/components/CreateDachverbandUserComponent.vue";

@Component({
  components: { CreateDachverbandUserComponent },
})
export default class FirstSetupView extends Vue {}
