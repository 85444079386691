import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import ClubDto from "@/dtos/ClubDto";

export interface ShowDialogData {
  isShowing: boolean;
  clubDto: ClubDto | null;
}

@Module({ namespaced: true })
export class ChangePasswordDialogModule extends VuexModule {
  private _isShowing = false;
  private _id = "";

  @Mutation
  public show(userId: string): void {
    this._isShowing = !!userId;
    this._id = userId;
  }

  @Mutation
  public showChangeOwnPassword(): void {
    this._isShowing = true;
    this._id = "";
  }

  public get isShowing(): boolean {
    return this._isShowing;
  }

  public get userId(): string {
    return this._id;
  }

  public get isChangeOwnPassword(): boolean {
    return !this._id;
  }

  @Mutation
  public hide(): void {
    this._isShowing = false;
  }
}
