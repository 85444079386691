




import { Component, Vue } from "vue-property-decorator";
import SettingsComponent from "@/components/SettingsComponent.vue";

@Component({
  components: { SettingsComponent },
})
export default class SettingsView extends Vue {}
