import Vuex from "vuex";
import Vue from "vue";
import VuexPersistence from "vuex-persist";
import AuthModule from "@/store/Auth.module";
import { ClubDialogModule } from "@/store/ClubDialog.module";
import { UserDialogModule } from "@/store/UserDialog.module";
import { ChangePasswordDialogModule } from "@/store/ChangePassword.module";
import { PlayerDialogModule } from "@/store/PlayerDialog.module";
import { PlayersListDialogModule } from "@/store/PlayersListDialog.module";
import { PaymentsListDialogModule } from "@/store/PaymentsListDialog.module";
import { PaymentsDetailsDialogModule } from "@/store/PaymentsDetailsDialog.module";

Vue.use(Vuex);

const vuexPersist = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    clubDetails: ClubDialogModule,
    playerDetails: PlayerDialogModule,
    userDetails: UserDialogModule,
    changePasswordDialog: ChangePasswordDialogModule,
    playersListDialog: PlayersListDialogModule,
    paymentsListDialog: PaymentsListDialogModule,
    paymentsDetailsDialog: PaymentsDetailsDialogModule,
  },
  plugins: [vuexPersist.plugin],
});
