import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import AuthResultDto from "@/dtos/AuthResultDto";
import { JwtTokenDto } from "@/dtos/JwtTokenDto";
import { authService } from "@/rest/auth.service";
import CreateUserDto from "@/dtos/CreateUserDto";
import router from "@/router";
import { Route } from "vue-router";
import axios from "axios";
import UserAuthDto from "@/dtos/UserAuthDto";
import RefreshTokenRequestDto from "@/dtos/RefreshTokenRequestDto";
import { usersService } from "@/rest/users.service";

@Module({ namespaced: true })
export default class AuthModule extends VuexModule {
  private tokens: AuthResultDto | null = null;

  @Mutation
  public setTokens(newTokens: AuthResultDto | null): void {
    this.tokens = newTokens;
    if (newTokens && newTokens.accessToken?.token) {
      axios.defaults.headers.common.Authorization = `Bearer ${newTokens.accessToken.token}`;
    } else {
      axios.defaults.headers.common.Authorization = "";
    }
  }

  @Action({ rawError: true })
  public async createInitialUser(createUserDto: CreateUserDto): Promise<Route> {
    const authResult = await usersService.createInitialUser(createUserDto);
    this.context.commit("setTokens", authResult);
    return await router.push({ path: "/" });
  }

  @Action({ rawError: true })
  public async login(userAuthDto: UserAuthDto): Promise<void> {
    const authResult = await authService.login(userAuthDto);
    this.context.commit("setTokens", authResult);
    await router.push({ path: "/" });
  }

  @Action({ rawError: true })
  public async refresh(): Promise<void> {
    const dto: RefreshTokenRequestDto = {
      accessToken: this.accessToken?.token || "",
      refreshToken: this.refreshToken?.token || "",
    };

    const refreshResult = await authService.refresh(dto);
    this.context.commit("setTokens", refreshResult);
  }

  @Action({ rawError: true })
  public async logout(): Promise<void> {
    try {
      await authService.logout();
    } finally {
      this.context.commit("setTokens", null);
    }

    await router.push("login");
  }

  get accessToken(): JwtTokenDto | null {
    if (this.tokens == null) {
      return null;
    }

    return this.tokens.accessToken;
  }

  get isLoggedIn(): boolean {
    return !!this.tokens;
  }

  get refreshToken(): JwtTokenDto | null {
    if (this.tokens == null) {
      return null;
    }

    return this.tokens.refreshToken;
  }

  get isTournamentManager(): boolean {
    return this.tokens?.isTournamentManager || false;
  }

  get isDachverbandUser(): boolean {
    return this.tokens?.isDachverbandUser || false;
  }

  get isClubManager(): boolean {
    return this.tokens?.isClubManager || false;
  }

  get authenticatedClubId(): string | null {
    return this.tokens?.clubId || null;
  }
}
