




































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import PlayerPaidExcelColumnSelectionComponent from "@/components/PlayerPaidExcelColumnSelectionComponent.vue";
import { KEYS } from "@/plugins/i18n";
import {
  MultiCreatePlayerRequestDto,
  validateMultiCreatePlayerRequestDto,
} from "@/dtos/MultiCreatePlayerRequestDto";
import PlayerDto from "@/dtos/PlayerDto";
import Genders from "@/dtos/Gender";
import { Auth } from "@/store/namespaces";
import { playersService } from "@/rest/players.service";
import { ExcelUtils } from "@/utils/ExcelUtils";
import moment from "moment";
import { translateErrorCode } from "@/rest/ErrorCodes";

@Component({
  components: { PlayerPaidExcelColumnSelectionComponent },
})
export default class ImportPlayersComponent extends Vue {
  @Prop()
  private jsonData: (string | number)[][] | null = null;

  private static readonly INFO_COL_INDEX = 13;

  @Emit()
  CreatedSuccessfully(): void {
    return;
  }

  @Auth.Getter
  private authenticatedClubId!: string;

  private hasValidationErrors = false;

  private showErrorSnackbar = false;
  private errorText = "";

  private sCreate = this.$t(KEYS.common.create).toString();

  private headers: string[] = [
    this.$t(KEYS.players.firstName).toString(),
    this.$t(KEYS.players.lastName).toString(),
    this.$t(KEYS.players.gender).toString(),
    this.$t(KEYS.players.birthday).toString(),
    this.$t(KEYS.players.youthPlayer).toString(),
    this.$t(KEYS.players.pdgaNumber).toString(),
    this.$t(KEYS.players.street).toString(),
    this.$t(KEYS.players.streetNumber).toString(),
    this.$t(KEYS.players.zipCode).toString(),
    this.$t(KEYS.players.city).toString(),
    this.$t(KEYS.players.country).toString(),
    this.$t(KEYS.players.email).toString(),
    this.$t(KEYS.players.phone).toString(),
    this.$t(KEYS.common.info).toString(),
  ];

  private rows: (string | number)[][] = [];
  private dtosToCreate: MultiCreatePlayerRequestDto[] = [];

  @Watch("jsonData")
  private async onJsonDataChanged(newValue?: Array<string | number>[]) {
    if (!newValue) {
      this.hasValidationErrors = false;
      this.rows = [];
    } else {
      try {
        this.hasValidationErrors = false;
        this.dtosToCreate = this.createRequestDtoFromExcelRows(newValue);
        this.rows = this.dtosToCreate.map((dto, i) =>
          this.dtoToTableRow(newValue[i], dto)
        );
      } catch (e) {
        this.errorText = this.$t(KEYS.error.invalid_file_uploaded).toString();
        this.showErrorSnackbar = true;
      }
    }
  }

  private dtoToTableRow(
    excelRow: (string | number)[],
    dto: MultiCreatePlayerRequestDto
  ): (string | number)[] {
    const bdayColIndex = 3;
    const result = [...excelRow];
    const validationErrorMessage = validateMultiCreatePlayerRequestDto(dto);

    result[bdayColIndex] = moment(
      dto.player.birthday?.toISOString()?.substr(0, 10) ?? ""
    ).format("D.M.YYYY");
    if (validationErrorMessage != "") {
      this.hasValidationErrors = true;
    }
    result[ImportPlayersComponent.INFO_COL_INDEX] = validationErrorMessage;

    return result;
  }

  private createRequestDtoFromExcelRows(
    excelRow: (string | number)[][]
  ): MultiCreatePlayerRequestDto[] {
    return excelRow.map<MultiCreatePlayerRequestDto>((row, i) => {
      const playerDto = new PlayerDto();
      playerDto.firstName = row[0]?.toString();
      playerDto.lastName = row[1]?.toString();

      const genderCol = row[2]?.toString().toLowerCase();
      if (genderCol?.startsWith("m")) {
        playerDto.gender = Genders.MALE.value;
      } else if (genderCol?.startsWith("w")) {
        playerDto.gender = Genders.FEMALE.value;
      } else if (genderCol?.startsWith("d")) {
        playerDto.gender = Genders.DIVERSE.value;
      } else {
        playerDto.gender = 9999; // some invalid gender value
      }

      playerDto.birthday = ExcelUtils.GetDateValueOrNull(row[3]);
      const youthValue = row[4]?.toString().toLowerCase();
      playerDto.isYouthPlayer =
        youthValue.startsWith("y") || youthValue.startsWith("j");

      playerDto.pdgaNumber = row[5]?.toString();
      playerDto.street = row[6]?.toString();
      playerDto.streetNumber = row[7]?.toString();
      playerDto.zipCode = row[8]?.toString();
      playerDto.city = row[9]?.toString();
      playerDto.country = row[10]?.toString();
      playerDto.email = row[11]?.toString();
      playerDto.phone = row[12]?.toString();
      playerDto.mainClubId = this.authenticatedClubId;

      return {
        player: playerDto,
        rowNumber: i,
      };
    });
  }

  private async doImportPlayers() {
    if (this.dtosToCreate && this.dtosToCreate.length > 0) {
      try {
        const result = await playersService.createMultiple(this.dtosToCreate);
        let hasSingleCreateError = false;
        const newRows = [...this.rows];
        result.forEach((createPlayerResult) => {
          if (createPlayerResult.error) {
            hasSingleCreateError = true;
            const row = newRows[createPlayerResult.rowNumber];
            row[ImportPlayersComponent.INFO_COL_INDEX] = translateErrorCode(
              createPlayerResult.error
            );
          }
        });

        if (hasSingleCreateError) {
          this.rows = newRows;
          this.errorText = this.$t(KEYS.error.invalid_file_uploaded).toString();
          this.showErrorSnackbar = true;
        } else {
          this.CreatedSuccessfully();
        }
      } catch (e) {
        this.errorText = e.message;
        this.showErrorSnackbar = true;
      }
    }
  }
}
