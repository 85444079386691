






















import Vue from "vue";
import Component from "vue-class-component";
import { Auth, ChangePasswordDialog } from "@/store/namespaces";
import { Route } from "vue-router";
import { KEYS } from "@/plugins/i18n";
import ChangePasswordComponent from "@/components/ChangePasswordComponent.vue";

export interface ToolbarLink {
  title: string;
  to: string;
}

@Component({
  components: { ChangePasswordComponent },
})
export default class Navbar extends Vue {
  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Getter
  private isTournamentManager!: boolean;

  @Auth.Getter
  private isDachverbandUser!: boolean;

  @Auth.Getter
  private isClubManager!: boolean;

  @Auth.Action
  private logout!: () => Promise<Route>;

  @ChangePasswordDialog.Mutation
  private showChangeOwnPassword!: () => void;

  private s_changePassword: string = this.$t(
    KEYS.users.change_password
  ).toString();

  private changePasswordClick() {
    this.showChangeOwnPassword();
  }

  private get links(): ToolbarLink[] {
    const link_players = {
      title: this.$t(KEYS.common.members).toString(),
      to: "/players",
    };
    const link_clubs = {
      title: this.$t(KEYS.common.clubs).toString(),
      to: "/clubs",
    };
    const link_users = {
      title: this.$t(KEYS.users.users).toString(),
      to: "/users",
    };
    const link_settings = {
      title: this.$t(KEYS.common.settings).toString(),
      to: "/settings",
    };

    if (this.isDachverbandUser) {
      return [link_players, link_clubs, link_users, link_settings];
    } else {
      return [link_players];
    }
  }
}
