







import { Component, Vue } from "vue-property-decorator";
import ClubDetailsComponent from "@/components/ClubDetailsComponent.vue";
import ClubListComponent from "@/components/ClubListComponent.vue";
import ClubDto from "@/dtos/ClubDto";
import { PlayersListDialog } from "@/store/namespaces";
import PlayersListDialogComponent from "@/components/PlayersListDialogComponent.vue";

@Component({
  components: {
    PlayersListDialogComponent,
    ClubListComponent,
    ClubDetailsComponent,
  },
})
export default class ClubsView extends Vue {
  @PlayersListDialog.Mutation
  showPlayersListDialog!: (club: ClubDto) => void;
}
