import axios, { AxiosError } from "axios";
import ClubDto from "@/dtos/ClubDto";
import i18n, { KEYS } from "@/plugins/i18n";
import { handleErrorCodeException } from "@/rest/ErrorCodes";

export enum ClubsErrorCodes {
  Club_has_members = 1,
}

class ClubsService {
  private readonly path = "api/clubs";
  private readonly pathSecondaryClubs = this.path + "/secondary";

  public async createClub(dto: ClubDto): Promise<ClubDto | null> {
    try {
      dto.id = null;
      const response = await axios.post<ClubDto>(this.path, dto);
      return response.data;
    } catch (e) {
      throw handleErrorCodeException(e);
    }
  }

  public async getAll() {
    const response = await axios.get<ClubDto[]>(this.path);
    return response.data;
  }

  public async update(clubDto: ClubDto) {
    await axios.put(this.path, clubDto);
  }

  async getSecondaryClubs(playerId: string): Promise<ClubDto[]> {
    const response = await axios.get<ClubDto[]>(
      `${this.pathSecondaryClubs}/${playerId}`
    );

    return response.data;
  }

  async delete(club: ClubDto | null) {
    if (club == null) {
      return;
    }

    try {
      await axios.delete<string>(`${this.path}/${club.id}`);
    } catch (e) {
      const axiosError = e as AxiosError;
      if (axiosError) {
        if (
          axiosError.response?.data.toString() ===
          ClubsErrorCodes.Club_has_members.toString()
        ) {
          throw new Error(i18n.t(KEYS.error.club_has_members).toString());
        } else throw axiosError;
      }
    }
  }
}

export const clubsService = new ClubsService();
