



























import { Component, Vue, Watch } from "vue-property-decorator";
import UserDetailsComponent from "@/components/UserDetailsComponent.vue";
import ChangePasswordComponent from "@/components/ChangePasswordComponent.vue";
import TableComponent from "@/components/TableComponent.vue";
import PlayerDetailsComponent from "@/components/PlayerDetailsComponent.vue";
import { KEYS } from "@/plugins/i18n";
import moment from "moment";
import { settingsService } from "@/rest/settings.service";

@Component({
  components: {
    PlayerDetailsComponent,
    TableComponent,
    ChangePasswordComponent,
    UserDetailsComponent,
  },
})
export default class SettingsComponent extends Vue {
  private sSettings = this.$t(KEYS.common.settings).toString();
  private sSaisonBegin = this.$t(KEYS.settings.saisonBegin).toString();

  private seasonBeginYearMonth: string | null = null;
  private saisonBeginText: string | null = null;

  async mounted(): Promise<void> {
    const settings = await settingsService.get();
    moment.locale(this.$i18n.locale);
    this.saisonBeginText = moment(settings.seasonStart).format("MMM");
    this.seasonBeginYearMonth = moment(settings.seasonStart).format("yyyy-MM");
  }

  @Watch("seasonBeginYearMonth")
  private saisonBeginDateChange(newValue: string, oldValue: string) {
    if (oldValue == null) {
      // when set for the first time
      return;
    }

    moment.locale(this.$i18n.locale);
    this.saisonBeginText = moment(newValue).format("MMM");
    settingsService.setSeasonBegin(new Date(newValue));
  }
}
