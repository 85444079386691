
















































































import Component from "vue-class-component";
import Vue from "vue";
import { KEYS } from "@/plugins/i18n";
import { AxiosError } from "axios";
import { Auth, UserDetails } from "@/store/namespaces";
import { Ref } from "vue-property-decorator";
import GetUserListDto from "@/dtos/GetUserListDto";
import { UserErrorCodes, usersService } from "@/rest/users.service";
import { UpdateUserDtoFromListUserDto } from "@/dtos/UpdateUserDto";
import { CreateUserDtoFromGetUserListDto } from "@/dtos/CreateUserDto";
import Roles from "@/dtos/Roles";
import { clubsService } from "@/rest/clubs.service";
import ClubDto, { emptyClubDto } from "@/dtos/ClubDto";

type RoleEntry = { key: string; value: string };

@Component
export default class UserDetailsComponent extends Vue {
  private isValid = false;
  private l_name = this.$t(KEYS.users.username).toString();
  private l_email = this.$t(KEYS.users.email).toString();
  private l_Password: string = this.$t(KEYS.users.password).toString();
  private l_PasswordRepeat: string = this.$t(
    KEYS.users.password_repeat
  ).toString();
  private l_club = this.$t(KEYS.common.club).toString();
  private s_save = this.$t(KEYS.common.save).toString();
  private s_cancel = this.$t(KEYS.common.cancel).toString();
  private s_valueRequired = this.$t(KEYS.error.value_required).toString();
  private sTypeOfUser = this.$t(KEYS.users.type_of_user).toString();
  private sPasswordRequired: string = this.$t(
    KEYS.users.passwordRequired
  ).toString();
  private sPasswordsDontMatch = this.$t(
    KEYS.users.passwords_dont_match
  ).toString();

  private errorMessage: string | null = null;
  private password = "";
  private passwordRepeat = "";

  private get role(): RoleEntry | null {
    if (!this.userDto) {
      return null;
    }

    if (this.userDto.isDachverbandUser) {
      return Roles.DACHVERBAND;
    }
    if (this.userDto.isClubManager) {
      return Roles.CLUB_MANAGER;
    }
    if (this.userDto.isTournamentManager) {
      return Roles.TOURNAMENT_MANAGER;
    }

    return null;
  }

  private set role(roleEntry: RoleEntry | null) {
    if (!this.userDto) {
      return;
    }

    this.userDto.isDachverbandUser = false;
    this.userDto.isClubManager = false;
    this.userDto.isTournamentManager = false;

    if (roleEntry == null) {
      return;
    }

    switch (roleEntry.key) {
      case Roles.DACHVERBAND.key:
        this.userDto.isDachverbandUser = true;
        break;
      case Roles.CLUB_MANAGER.key:
        this.userDto.isClubManager = true;
        break;
      case Roles.TOURNAMENT_MANAGER.key:
        this.userDto.isTournamentManager = true;
        break;
    }

    if (!this.userDto.isClubManager) {
      this.userDto.club = null;
    }

    this.form.validate();
  }

  private clubs: ClubDto[] = [];

  private async created(): Promise<void> {
    if (this.isDachverbandUser) {
      this.clubs = await clubsService.getAll();
    }
  }

  get typeOfUserItemsDDLEntries(): RoleEntry[] {
    let result: RoleEntry[] = [];
    for (let role in Roles) {
      if (role.toLowerCase() != Roles.CLUB_MANAGER.key) {
        // club manager is set via the club settings
        const roleEntry: RoleEntry = Roles[role as keyof typeof Roles];
        result.push(Object.freeze(roleEntry));
      }
    }

    return result;
  }

  @Ref()
  readonly form!: HTMLFormElement;

  @UserDetails.Getter
  private isShowing!: boolean;
  @UserDetails.Mutation
  private hide!: () => void;
  @UserDetails.Mutation
  private setClubId!: (newClubId: string) => void;

  @UserDetails.Getter
  private userDto!: GetUserListDto;
  @Auth.Getter
  private authenticatedClubId!: string;
  @Auth.Getter
  private isDachverbandUser!: boolean;

  get _isShowing(): boolean {
    return this.isShowing;
  }

  private get isCreate(): boolean {
    return !this.userDto?.id;
  }

  get heading(): string {
    if (this.isCreate) {
      return this.$t(KEYS.headings.create_user).toString();
    } else {
      return this.$t(KEYS.headings.edit_user).toString();
    }
  }

  async submit(): Promise<void> {
    this.errorMessage = null;
    try {
      const forcedClubId = this.authenticatedClubId;
      if (forcedClubId) {
        this.userDto.club = emptyClubDto();
        this.userDto.isClubManager = true;
      }
      if (this.isCreate) {
        await usersService.create(
          CreateUserDtoFromGetUserListDto(this.userDto, this.password)
        );
      } else {
        await usersService.update(UpdateUserDtoFromListUserDto(this.userDto));
      }
      this.hide();
    } catch (e) {
      const axiosError = e as AxiosError;
      const response = axiosError.response;
      //conflict
      if (response?.status === 409) {
        if (response.data === UserErrorCodes.Username) {
          this.errorMessage = this.$t(
            KEYS.error.username_already_exists
          ).toString();
        } else if (response.data === UserErrorCodes.Email) {
          this.errorMessage = this.$t(
            KEYS.error.email_already_exists
          ).toString();
        }
      }
    }
  }
}
