import axios from "axios";
import PaymentDto from "@/dtos/PaymentDto";
import { MultiCreatePaymentsRequestDto } from "@/dtos/MultiCreatePaymentsRequestDto";
import { MultiCreatePaymentsResponseDto } from "@/dtos/MultiCreatePaymentsResponseDto";
import { handleErrorCodeException } from "@/rest/ErrorCodes";

class PaymentsService {
  private readonly path = "api/payments";

  public async getByPlayerId(playerId: string): Promise<PaymentDto[]> {
    const response = await axios.get<PaymentDto[]>(`${this.path}/${playerId}`);
    return response.data;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private replaceUnderlineFromPropertiesJson(dto: object): unknown {
    const json = JSON.stringify(dto);
    return JSON.parse(json.replace(/"_/g, '"'));
  }

  public async createPayment(
    dto: PaymentDto | null,
    playerId: string
  ): Promise<PaymentDto | null> {
    if (dto == null) {
      return null;
    }

    dto.id = null;
    dto.playerId = playerId;

    const replacedDto = this.replaceUnderlineFromPropertiesJson(dto);

    const response = await axios.put<PaymentDto>(this.path, replacedDto);
    return response.data;
  }

  async createMultiple(dtos: MultiCreatePaymentsRequestDto[]) {
    try {
      const replacedDtos = this.replaceUnderlineFromPropertiesJson(dtos);
      const response = await axios.put<MultiCreatePaymentsResponseDto[]>(
        this.path + "/createmulti",
        replacedDtos
      );
      return response.data;
    } catch (e) {
      throw handleErrorCodeException(e);
    }
  }
}

export const paymentsService = new PaymentsService();
