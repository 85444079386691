




import { Component, Vue } from "vue-property-decorator";
import UsersListComponent from "@/components/UsersListComponent.vue";

@Component({
  components: { UsersListComponent },
})
export default class UsersView extends Vue {}
