

















































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { KEYS } from "@/plugins/i18n";
import { ExcelColumDefinition } from "@/dtos/CheckPaymentStatusRequestDto";
import PlayerPaidExcelColumnSelectionComponent from "@/components/PlayerPaidExcelColumnSelectionComponent.vue";
import PaymentsUploadColumnChooseComponent from "@/components/PaymentsUploadColumnChooseComponent.vue";
import { playersService } from "@/rest/players.service";
import PaymentDtoClass from "@/dtos/PaymentDto";
import { paymentsService } from "@/rest/payments.service";
import { MultiCreatePaymentsRequestDto } from "@/dtos/MultiCreatePaymentsRequestDto";
import { translateErrorCode } from "@/rest/ErrorCodes";

@Component({
  components: {
    PaymentsUploadColumnChooseComponent,
    PlayerPaidExcelColumnSelectionComponent,
  },
})
export default class ImportPaymentsComponent extends Vue {
  @Prop()
  public jsonData: string[][] | null = null;
  private columnSelection: ExcelColumDefinition | null = null;
  private selectedColumnIndices: number[] = [];
  private statusColumnIndex = -1;
  private sInvalidRow = this.$t(KEYS.error.player_number_required).toString();

  @Emit()
  CreatedSuccessfully(): void {
    return;
  }

  public hasValidationErrors = false;
  public showColumnChooseDialog = false;

  showErrorSnackbar = false;
  public errorText = "";

  sCreate = this.$t(KEYS.payments.importPayments).toString();

  public get headers(): string[] {
    if (!this.jsonData) {
      return [];
    }
    return [...this.jsonData[0], ""]; // empty string for status column
  }

  rows: string[][] = [];

  @Watch("jsonData")
  private async onJsonDataChanged(newValue?: string[][]) {
    if (!newValue) {
      this.hasValidationErrors = false;
      this.errorText = "";
      this.rows = [];
      return;
    }
    this.showColumnChooseDialog = true;
  }

  async doImportPayments(): Promise<void> {
    const dtos = await this.createRequestDtoFromExcelRows(this.rows);

    if (dtos && dtos.length > 0) {
      try {
        const result = await paymentsService.createMultiple(dtos);
        let hasSingleCreateError = false;
        const newRows = [...this.rows];
        result.forEach((createPlayerResult) => {
          if (createPlayerResult.error) {
            hasSingleCreateError = true;
            const row = newRows[createPlayerResult.rowNumber];
            row[this.statusColumnIndex] = translateErrorCode(
              createPlayerResult.error
            );
          }
        });

        if (hasSingleCreateError) {
          this.rows = newRows;
          this.errorText = this.$t(KEYS.error.invalid_file_uploaded).toString();
          this.showErrorSnackbar = true;
        } else {
          this.CreatedSuccessfully();
        }
      } catch (e) {
        this.errorText = e.message;
        this.showErrorSnackbar = true;
      }
    }
  }

  private async createRequestDtoFromExcelRows(
    excelRow: string[][]
  ): Promise<Array<MultiCreatePaymentsRequestDto>> {
    if (!this.columnSelection) {
      return [];
    }
    const oedgvIndex = this.columnSelection.oedgvColumnIndex;
    const hasPaidIndex = this.columnSelection.hasPaidColumnIndex;
    return (
      await Promise.all(
        excelRow.map<Promise<MultiCreatePaymentsRequestDto | null>>(
          async (row, i) => {
            let hasPaid = false;
            try {
              hasPaid = row[hasPaidIndex].trim() !== "";
            } catch {
              // do nothing
            }
            if (!hasPaid) {
              return null;
            }

            let paymentDto = await PaymentDtoClass.defaultPaymentDto();
            const playerNumber = row[oedgvIndex];
            try {
              paymentDto.playerId = (
                await playersService.getPlayerByPlayerNumber(playerNumber)
              ).id;
            } catch (e) {
              paymentDto.playerId = null;
            }
            return {
              rowNumber: i,
              payment: paymentDto,
              //rowNumber: i + 2, //bcs we removed the header row before - and zero based vs 1 based in excel
            };
          }
        )
      )
    ).filter((x) => x !== null) as MultiCreatePaymentsRequestDto[];
  }

  public onExcelColumnsChosen(columnSelection: ExcelColumDefinition): void {
    this.showColumnChooseDialog = false;
    this.columnSelection = columnSelection;
    this.selectedColumnIndices = this.columnSelection.allSelectedIndices;
    this.hasValidationErrors = false;
    this.errorText = "";
    this.parseExcel();
  }

  private parseExcel(): void {
    if (!this.jsonData) {
      return;
    }

    const newArray = [...this.jsonData];
    const headers = this.headers;
    newArray.shift();
    newArray.map((row) => {
      row.length = headers.length;

      return Array.from(row, (v) => v ?? ""); // pad with empty string
    });

    this.rows = Array.from(newArray, (v) => v ?? "");
    this.statusColumnIndex = this.rows[0].length - 1;
    this.hasValidationErrors = this.HasAnyValidationError(this.rows);
  }

  private HasAnyValidationError(rows: string[][]) {
    let hasErrors = false;
    let oedgvNumberIndex = this.columnSelection?.oedgvColumnIndex;
    if (!oedgvNumberIndex) {
      return true;
    }

    for (let row of rows) {
      let oedgvNumberTrimmed = row[oedgvNumberIndex]?.trim() || "";
      if (oedgvNumberTrimmed === "") {
        hasErrors = true;
        row[this.statusColumnIndex] = this.sInvalidRow;
      }
    }
    return hasErrors;
  }

  public getColumnClass(i: number): string {
    return this.selectedColumnIndices.includes(i) ? "highlighted" : "";
  }
}
