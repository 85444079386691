import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import i18n from "@/plugins/i18n";
import axios from "axios";
import { JwtTokenDto } from "@/dtos/JwtTokenDto";
import { authService } from "@/rest/auth.service";

Vue.config.productionTip = false;

const accessTokenDto: JwtTokenDto = store.getters["auth/accessToken"];
if (accessTokenDto) {
  axios.defaults.headers.common.Authorization = `Bearer ${accessTokenDto.token}`;
}

axios.interceptors.response.use(
  (response) => {
    handleDates(response.data);
    return response;
  },
  async (err) => {
    // no config aor no response given
    if (!err.config || !err.response) {
      return Promise.reject(err);
    }

    const url = err.config.url;
    // refresh or login failed => we cannot refresh
    if (url === authService.loginUrl || url === authService.refreshUrl) {
      return Promise.reject(err);
    }

    // not unauthorized => we cannot refresh
    if (err.response.status !== 401) {
      return Promise.reject(err);
    }

    // we can refresh if we get here
    try {
      await store.dispatch("auth/refresh");
      const newAccessToken: JwtTokenDto = store.getters["auth/accessToken"];
      err.config.headers.Authorization = "Bearer " + newAccessToken.token;
      return axios.request(err.config); // that means we repeat the request
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

//eslint-disable-next-line
function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

//eslint-disable-next-line
function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      const _tempDate = new Date(value);
      body[key] = new Date(
        _tempDate.getTime() - _tempDate.getTimezoneOffset() * 60000
      );
    } else if (typeof value === "object") handleDates(value);
  }
}

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
