














































import Vue from "vue";
import Component from "vue-class-component";
import { KEYS } from "@/plugins/i18n";
import CreateUserDto, { EmptyCreateUserDto } from "@/dtos/CreateUserDto";
import { Auth } from "@/store/namespaces";

@Component
export default class CreateDachverbandUserComponent extends Vue {
  private heading: string = this.$t(
    KEYS.headings.create_dachverbanduser
  ).toString();

  private sCreate: string = this.$t(KEYS.common.create).toString();
  private sPasswordRequired: string = this.$t(
    KEYS.users.passwordRequired
  ).toString();
  private sUsernameRequired: string = this.$t(
    KEYS.users.usernameRequired
  ).toString();
  private sPasswordsDontMatch = this.$t(
    KEYS.users.passwords_dont_match
  ).toString();

  private l_Username: string = this.$t(KEYS.users.username).toString();
  private l_Email = this.$t(KEYS.users.email).toString();
  private l_Password: string = this.$t(KEYS.users.password).toString();
  private l_PasswordRepeat: string = this.$t(
    KEYS.users.password_repeat
  ).toString();

  isValid = false;

  private userDto: CreateUserDto = EmptyCreateUserDto();
  private passwordRepeat: string | null = null;

  private submit(): void {
    this.createInitialUser(this.userDto);
  }

  @Auth.Action
  private createInitialUser!: (createUserDto: CreateUserDto) => Promise<void>;
}
