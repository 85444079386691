















import { Component, Vue, Watch } from "vue-property-decorator";
import { KEYS } from "@/plugins/i18n";
import { PaymentsDetailsDialog, PaymentsListDialog } from "@/store/namespaces";
import { paymentsService } from "@/rest/payments.service";
import { nameof } from "ts-simple-nameof";
import TableComponent from "@/components/TableComponent.vue";
import PaymentDetailsDialogComponent from "@/components/PaymentDetailsDialogComponent.vue";
import PaymentDtoClass, { PaymentDto } from "@/dtos/PaymentDto";
import { CustomFilters } from "@/plugins/filters";

@Component({
  components: { PaymentDetailsDialogComponent, TableComponent },
})
export default class PlayersListComponent extends Vue {
  @PaymentsListDialog.Getter
  private PaymentsListDialogPlayerId!: string | null;

  @PaymentsDetailsDialog.Mutation
  showPaymentDetailsDialog!: (dto: PaymentDto | null) => void;

  @PaymentsDetailsDialog.Getter
  isPaymentDetailsDialogShowing!: boolean;

  private sPayments = this.$t(KEYS.payments.payments).toString();

  private payments: PaymentDto[] = [];

  private headers = [
    {
      text: this.$t(KEYS.payments.saison_start).toString(),
      value: nameof<PaymentDto>((p) => p.periodStart),
      align: "center",
      formatter: CustomFilters.seasonStart,
    },
    {
      text: this.$t(KEYS.payments.payment_date).toString(),
      value: nameof<PaymentDto>((p) => p.paymentDate),
      align: "center",
      formatter: CustomFilters.date,
    },
  ];

  async mounted(): Promise<void> {
    return await this.loadData();
  }

  @Watch("isPaymentDetailsDialogShowing")
  onPaymentsDetailsDialogShowingChange(newVal: boolean): void {
    if (!newVal) {
      this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    if (this.PaymentsListDialogPlayerId != null) {
      this.payments = []; // set empty to show nothing while the rest request is going on.
      this.payments = await paymentsService.getByPlayerId(
        this.PaymentsListDialogPlayerId
      );
    }
  }

  private async createClick() {
    const dto = await PaymentDtoClass.defaultPaymentDto();
    this.showPaymentDetailsDialog(dto);
  }

  @Watch("PaymentsListDialogPlayerId")
  private async onIsShowingChange(newValue: string): Promise<void> {
    if (newValue) {
      await this.loadData();
    }
  }
}
