






import { Component, Prop, Vue } from "vue-property-decorator";
import { PaymentsListDialog } from "@/store/namespaces";
import PaymentsListComponent from "@/components/PaymentsListComponent.vue";

@Component({
  components: { PaymentsListComponent },
})
export default class PaymentsListDialogComponent extends Vue {
  @PaymentsListDialog.Getter
  private isPaymentsListDialogShowing!: boolean;
  @PaymentsListDialog.Mutation
  private hidePaymentsListDialog!: () => void;

  @Prop()
  private readonly maxWidth!: string;

  private get isShowing(): boolean {
    return this.isPaymentsListDialogShowing;
  }

  private set isShowing(newVal: boolean) {
    if (!newVal) {
      this.hidePaymentsListDialog();
    }
  }
}
