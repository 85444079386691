import i18n, { KEYS } from "@/plugins/i18n";
const InstructorLevels = {
  UEBUNGSLEITER: {
    value: 0,
    text: i18n.t(KEYS.instructorLevels.uebungsleiter).toString(),
  },
  INSTRUKTOR: {
    value: 1,
    text: i18n.t(KEYS.instructorLevels.instruktor).toString(),
  },
  TRAINER: {
    value: 2,
    text: i18n.t(KEYS.instructorLevels.trainer).toString(),
  },
};

export default InstructorLevels;
