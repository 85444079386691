




































































import { Component, Vue, Watch } from "vue-property-decorator";
import { KEYS } from "@/plugins/i18n";
import { Auth, ChangePasswordDialog, UserDetails } from "@/store/namespaces";
import GetUserListDto from "@/dtos/GetUserListDto";
import { usersService } from "@/rest/users.service";
import UserDetailsComponent from "@/components/UserDetailsComponent.vue";
import { ShowUserDialogData } from "@/store/UserDialog.module";
import ChangePasswordComponent from "@/components/ChangePasswordComponent.vue";

@Component({
  components: { ChangePasswordComponent, UserDetailsComponent },
})
export default class UsersListComponent extends Vue {
  private users: GetUserListDto[] = [];
  private usersFiltered: GetUserListDto[] = [];
  private filterText = "";

  private sUsers = this.$t(KEYS.users.users).toString();
  private sDachverbandsuser = this.$t(KEYS.users.dachverbandusers).toString();
  private sVereinsuser = this.$t(KEYS.users.clubManagers).toString();
  private sTurnierleiter = this.$t(KEYS.users.tournamentManager).toString();
  private sSearch = this.$t(KEYS.common.search);
  private s_lockUser = this.$t(KEYS.users.lockUser);
  private s_unlockUser = this.$t(KEYS.users.unlockUser);

  private filterDachverbandusers = false;
  private filterClubMangers = false;
  private filterTournamentManagers = false;

  @UserDetails.Mutation
  show!: (data: ShowUserDialogData) => void;
  @UserDetails.Getter
  private isShowing!: boolean;
  @Auth.Getter
  private isDachverbandUser!: boolean;

  @ChangePasswordDialog.Mutation("show")
  private showChangeDialog!: (userId: string) => void;

  private editClick(userToEdit: GetUserListDto) {
    const clone = Object.assign({}, userToEdit);
    this.show({ userDto: clone, isShowing: true });
  }

  private createClick() {
    this.show({ userDto: null, isShowing: true });
  }

  async created(): Promise<void> {
    return await this.loadData();
  }

  private async loadData(): Promise<void> {
    this.users = await usersService.getAll();
    await this.filter();
  }

  private async filter(): Promise<void> {
    const searchLower = this.filterText?.toLowerCase() || "";
    this.usersFiltered = this.users;
    if (searchLower !== "") {
      this.usersFiltered = this.usersFiltered = this.users.filter((user) => {
        return user.username.toLowerCase().startsWith(searchLower);
      });
    }

    if (
      this.filterClubMangers ||
      this.filterDachverbandusers ||
      this.filterTournamentManagers
    ) {
      this.usersFiltered = this.usersFiltered.filter((user) => {
        let userTypeFilterResult = false;
        if (this.filterClubMangers && user.isClubManager) {
          userTypeFilterResult = true;
        } else if (this.filterDachverbandusers && user.isDachverbandUser) {
          userTypeFilterResult = true;
        } else if (this.filterTournamentManagers && user.isTournamentManager) {
          userTypeFilterResult = true;
        }

        return userTypeFilterResult;
      });
    }
  }

  @Watch("isShowing")
  private async onIsShowingChange(newValue: boolean): Promise<void> {
    if (!newValue) {
      await this.loadData();
    }
  }

  @Watch("filterDachverbandusers")
  private async onFilterDachverbandusersChanged(): Promise<void> {
    await this.filter();
  }

  @Watch("filterClubMangers")
  private async onFilterClubMangersChanged(): Promise<void> {
    await this.filter();
  }

  @Watch("filterTournamentManagers")
  private async onFilterTournamentManagersChanged() {
    await this.filter();
  }

  @Watch("filterText")
  private async onFilterTextChange() {
    await this.filter();
  }

  private changePasswordClick(dto: GetUserListDto) {
    this.showChangeDialog(dto.id);
  }

  private async toggleUserLockedClick(dto: GetUserListDto) {
    await usersService.toggleLocked(dto);
    dto.isLocked = !dto.isLocked;
  }
}
