

















































import Component from "vue-class-component";
import Vue from "vue";
import { KEYS } from "@/plugins/i18n";
import { ChangePasswordDialog } from "@/store/namespaces";
import { usersService } from "@/rest/users.service";
import { Ref } from "vue-property-decorator";
import { authService } from "@/rest/auth.service";

@Component
export default class ChangePasswordComponent extends Vue {
  private isValid = false;
  private l_currentPassword: string = this.$t(
    KEYS.users.current_password
  ).toString();
  private l_Password: string = this.$t(KEYS.users.password).toString();
  private l_PasswordRepeat: string = this.$t(
    KEYS.users.password_repeat
  ).toString();
  private s_save = this.$t(KEYS.common.save).toString();
  private s_cancel = this.$t(KEYS.common.cancel).toString();
  private sPasswordRequired: string = this.$t(
    KEYS.users.passwordRequired
  ).toString();
  private sPasswordsDontMatch = this.$t(
    KEYS.users.passwords_dont_match
  ).toString();

  @Ref()
  private form!: HTMLFormElement;

  @ChangePasswordDialog.Getter
  private userId!: string;
  @ChangePasswordDialog.Getter
  private isShowing!: boolean;
  @ChangePasswordDialog.Getter
  private isChangeOwnPassword!: boolean;
  @ChangePasswordDialog.Mutation
  private hide!: () => void;

  private _hide(): void {
    this.errorMessage = null;
    this.form.reset();
    this.hide();
  }

  private get heading(): string {
    if (this.isChangeOwnPassword) {
      return this.$t(KEYS.users.change_password).toString();
    } else {
      return this.$t(KEYS.headings.reset_password).toString();
    }
  }

  private set _isShowing(newVal: boolean) {
    this.errorMessage = null;
    if (!newVal) {
      this._hide();
    }
  }

  private get _isShowing(): boolean {
    return this.isShowing;
  }

  private errorMessage: string | null = null;
  private password = "";
  private passwordRepeat = "";
  private oldPassword = "";

  private async submit(): Promise<void> {
    try {
      if (this.isChangeOwnPassword) {
        await authService.changePassword(this.oldPassword, this.password);
      } else {
        await usersService.changePasswordAdmin(
          this.password,
          this.userId || ""
        );
      }
      this._isShowing = false;
    } catch (e) {
      this.errorMessage = this.$t(
        KEYS.error.invalidUsernamePassword
      ).toString();
    }
  }
}
