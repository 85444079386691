import axios from "axios";
import GetUserListDto from "@/dtos/GetUserListDto";
import CreateUserDto from "@/dtos/CreateUserDto";
import UpdateUserDto from "@/dtos/UpdateUserDto";
import AuthResultDto from "@/dtos/AuthResultDto";

export enum UserErrorCodes {
  Email = 0,
  Username = 1,
}

class UsersService {
  private readonly path = "api/users";
  private readonly pathChangePwAdmin = this.path + "/changepwadmin";
  public readonly initUrl = `${this.path}/init`;
  public readonly isInitializedUrl = `${this.path}/isinitialized`;
  public readonly LOCK = `lock`;
  public readonly UNLOCK = `unlock`;

  public async getAll(): Promise<GetUserListDto[]> {
    const response = await axios.get<GetUserListDto[]>(this.path);
    return response.data;
  }

  async create(dto: CreateUserDto): Promise<void> {
    return await axios.put(this.path, dto);
  }

  async update(userDto: UpdateUserDto): Promise<void> {
    return await axios.post(this.path, userDto);
  }

  async changePasswordAdmin(
    newPassword: string,
    userId: string
  ): Promise<void> {
    return await axios.post(this.pathChangePwAdmin, {
      id: userId,
      newPassword: newPassword,
    });
  }

  async createInitialUser(
    createUserDto: CreateUserDto
  ): Promise<AuthResultDto> {
    const response = await axios.post<AuthResultDto>(
      this.initUrl,
      createUserDto
    );
    return response.data;
  }

  async isInitialized(): Promise<boolean> {
    const response = await axios.get(this.isInitializedUrl);
    return response.data;
  }

  async toggleLocked(dto: GetUserListDto): Promise<void> {
    const action = dto.isLocked ? this.UNLOCK : this.LOCK;
    const url = `${this.path}/${dto.id}/${action}`;

    await axios.post(url);
  }
}

export const usersService = new UsersService();
