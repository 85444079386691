export default class CheckPaymentStatusRequestDto {
  rowNumber = 0;
  oedgvNumber = "";
  pdgaNumber = "";
  firstName = "";
  lastName = "";

  public static fromStringArray(
    rowNumber: number,
    row: string[],
    excelColumnDefinition: ExcelColumDefinition
  ): CheckPaymentStatusRequestDto {
    const result: CheckPaymentStatusRequestDto =
      new CheckPaymentStatusRequestDto();

    result.rowNumber = rowNumber;
    result.oedgvNumber =
      row.length > excelColumnDefinition.oedgvColumnIndex
        ? row[excelColumnDefinition.oedgvColumnIndex]
        : "";

    result.pdgaNumber =
      row.length > excelColumnDefinition.pdgaColumnIndex
        ? row[excelColumnDefinition.pdgaColumnIndex]
        : "";
    result.firstName =
      row.length > excelColumnDefinition.firstNameColumnIndex
        ? row[excelColumnDefinition.firstNameColumnIndex]
        : "";
    result.lastName =
      row.length > excelColumnDefinition.lastNameColumnIndex
        ? row[excelColumnDefinition.lastNameColumnIndex]
        : "";

    return result;
  }
}

export class ExcelColumDefinition {
  oedgvColumn = "";
  pdgaColumn = "";
  firstNameColumn = "";
  lastNameColumn = "";
  hasPaidColumn = "";

  public get oedgvColumnIndex(): number {
    return this.columnToIndex(this.oedgvColumn);
  }

  public get pdgaColumnIndex(): number {
    return this.columnToIndex(this.pdgaColumn);
  }

  public get firstNameColumnIndex(): number {
    return this.columnToIndex(this.firstNameColumn);
  }

  public get lastNameColumnIndex(): number {
    return this.columnToIndex(this.lastNameColumn);
  }

  public get hasPaidColumnIndex(): number {
    return this.columnToIndex(this.hasPaidColumn);
  }

  public get allSelectedColumns(): string[] {
    return [
      this.oedgvColumn,
      this.pdgaColumn,
      this.firstNameColumn,
      this.lastNameColumn,
      this.hasPaidColumn,
    ].filter((x) => x != "");
  }

  public get allSelectedIndices(): number[] {
    const selectedColumns = this.allSelectedColumns;

    return selectedColumns.map(this.columnToIndex);
  }

  private columnToIndex(columnLetter: string): number {
    if (columnLetter.charAt(0) == "" || columnLetter.charAt(0) == "-") {
      return Number.MAX_SAFE_INTEGER;
    }
    return columnLetter.toUpperCase().charCodeAt(0) - "A".charCodeAt(0);
  }
}
