import { render, staticRenderFns } from "./PlayersView.vue?vue&type=template&id=49377ee0&scoped=true&"
import script from "./PlayersView.vue?vue&type=script&lang=ts&"
export * from "./PlayersView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49377ee0",
  null
  
)

export default component.exports