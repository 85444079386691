import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import GetUserListDto from "@/dtos/GetUserListDto";
import { emptyClubDto } from "@/dtos/ClubDto";

export interface ShowUserDialogData {
  isShowing: boolean;
  userDto: GetUserListDto | null;
}

@Module({ namespaced: true })
export class UserDialogModule extends VuexModule {
  private _isShowing = false;
  private _data: GetUserListDto | null = null;

  @Mutation
  public show(data: ShowUserDialogData): void {
    this._isShowing = data.isShowing;
    if (data.isShowing) {
      this._data = data.userDto || {
        id: "",
        username: "",
        isDachverbandUser: false,
        email: "",
        club: emptyClubDto(),
        isClubManager: false,
        isTournamentManager: false,
        isLocked: false,
      };
    } else {
      this._data = null;
    }
  }

  public get isShowing(): boolean {
    return this._isShowing;
  }

  @Mutation
  public hide(): void {
    this._isShowing = false;
  }

  public get userDto(): GetUserListDto | null {
    return this._data;
  }
}
