






import { Component, Vue } from "vue-property-decorator";
import { PlayersListDialog } from "@/store/namespaces";
import PlayersListComponent from "@/components/PlayersListComponent.vue";
import ClubDto from "@/dtos/ClubDto";

@Component({
  components: { PlayersListComponent },
})
export default class PlayersListDialogComponent extends Vue {
  @PlayersListDialog.Getter
  private isPlayersListDialogShowing!: boolean;
  @PlayersListDialog.Mutation
  private hidePlayersListDialog!: () => void;
  @PlayersListDialog.Getter
  private playersListDialogClub!: ClubDto | null;

  private get clubFilterForDialog(): string | null {
    return this.playersListDialogClub?.id || null;
  }

  private get _isPlayersListDialogShowing(): boolean {
    return this.isPlayersListDialogShowing;
  }

  private set _isPlayersListDialogShowing(newVal: boolean) {
    if (!newVal) {
      this.hidePlayersListDialog();
    }
  }
}
