import PlayerDto from "@/dtos/PlayerDto";
import i18n, { KEYS } from "@/plugins/i18n";

export default class GetPlayerDto extends PlayerDto {
  mainClubName = "";
  get isYouthPlayer_text(): string {
    const key = this.isYouthPlayer ? KEYS.common.yes : KEYS.common.no;
    return i18n.t(key).toString().toLowerCase();
  }
}
