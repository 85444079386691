import i18n, { KEYS } from "@/plugins/i18n";
const Roles = {
  DACHVERBAND: {
    key: "dachverband",
    value: i18n.t(KEYS.users.dachverbanduser).toString(),
  },
  CLUB_MANAGER: {
    key: "club_manager",
    value: i18n.t(KEYS.users.clubManagers).toString(),
  },
  TOURNAMENT_MANAGER: {
    key: "tournament_manager",
    value: i18n.t(KEYS.users.tournamentManager).toString(),
  },
};

export default Roles;
