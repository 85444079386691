import GetUserListDto from "@/dtos/GetUserListDto";

export default interface UpdateUserDto {
  clubId: string | null;
  email: string;
  username: string;
  id: string;
}

export function UpdateUserDtoFromListUserDto(
  dto: GetUserListDto | null
): UpdateUserDto {
  if (dto == null) {
    return { clubId: null, id: "", email: "", username: "" };
  }
  return {
    clubId: dto.club?.id || null,
    id: dto.id,
    email: dto.email,
    username: dto.username,
  };
}
