import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import FirstSetupView from "@/views/FirstSetupView.vue";
import LoginView from "@/views/LoginView.vue";
import ClubsView from "@/views/ClubsView.vue";
import UsersView from "@/views/UsersView.vue";
import { navigationGuards } from "@/router/navigationGuards";
import PlayersView from "@/views/PlayersView.vue";
import SettingsView from "@/views/SettingsView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/first-setup",
    name: "FirstSetup",
    component: FirstSetupView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: navigationGuards.ensureInitialized,
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: { requiresAuth: true },
    beforeEnter: navigationGuards.onlyDachverband,
  },
  {
    path: "/clubs",
    name: "clubs",
    component: ClubsView,
    meta: { requiresAuth: true },
    beforeEnter: navigationGuards.onlyDachverband,
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: { requiresAuth: true },
    beforeEnter: navigationGuards.onlyDachverband,
  },
  {
    path: "/players",
    name: "users",
    component: PlayersView,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    redirect: "/players",
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(navigationGuards.requiresLogin);

export default router;
