import PlayerDto from "@/dtos/PlayerDto";
import i18n, { KEYS } from "@/plugins/i18n";
import { GetGenderTextByNumberValue } from "@/dtos/Gender";

export interface MultiCreatePlayerRequestDto {
  player: PlayerDto;
  rowNumber: number;
}

export function validateMultiCreatePlayerRequestDto(
  dto: MultiCreatePlayerRequestDto
): string {
  const player = dto.player;
  const sRequired = i18n.t(KEYS.error.value_required).toString() + ":";
  try {
    GetGenderTextByNumberValue(player.gender == null ? 9999 : player.gender);
  } catch (e) {
    return (
      i18n.t(KEYS.error.invalid_value).toString() +
      ": " +
      i18n.t(KEYS.players.gender).toString()
    );
  }

  if (!player.firstName?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.firstName).toString();
  }

  if (!player.lastName?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.lastName).toString();
  }

  if (!player.birthday) {
    return sRequired + " " + i18n.t(KEYS.players.birthday).toString();
  }

  if (!player.street?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.street).toString();
  }

  if (!player.street?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.street).toString();
  }

  if (!player.streetNumber?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.streetNumber).toString();
  }

  if (!player.zipCode?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.zipCode).toString();
  }

  if (!player.city?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.city).toString();
  }

  if (!player.country?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.country).toString();
  }

  if (!player.email?.trim().length) {
    return sRequired + " " + i18n.t(KEYS.players.email).toString();
  }

  return "";
}
