import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export class PaymentsListDialogModule extends VuexModule {
  private _playerId: string | null = null;

  @Mutation
  public showPaymentsListDialog(playerId: string | null): void {
    this._playerId = playerId;
  }

  public get isPaymentsListDialogShowing(): boolean {
    return !!this._playerId;
  }

  public get PaymentsListDialogPlayerId(): string | null {
    return this._playerId;
  }

  @Mutation
  public hidePaymentsListDialog(): void {
    this._playerId = null;
  }
}
