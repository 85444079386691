




import { Component, Vue } from "vue-property-decorator";
import PlayersListComponent from "@/components/PlayersListComponent.vue";

@Component({
  components: { PlayersListComponent },
})
export default class PlayersView extends Vue {}
