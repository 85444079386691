import i18n, { KEYS } from "@/plugins/i18n";
import { CustomFilters } from "@/plugins/filters";

export default class PlayerDto {
  id: string | null = null;
  playerNumber: number | null = null;
  city: string | null = null;
  country: string | null = null;
  email: string | null = null;
  gender: number | null = null;
  phone: string | null = null;
  street: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  instructorLevel: number | null = null;
  pdgaNumber: string | null = null;
  streetNumber: string | null = null;
  zipCode: string | null = null;
  wannaBeContacted = false;
  mainClubId: string | null = null;
  isYouthPlayer = false;
  birthday: Date | null = null;
  oedgvMembershipEndDate: Date | null = null;

  get isActiveOedgvMember(): boolean {
    const today = new Date();
    today.setHours(0);
    return (
      !!this.oedgvMembershipEndDate &&
      new Date(this.oedgvMembershipEndDate) >= today
    );
  }

  public get oedgvMembershipText(): string {
    if (this.oedgvMembershipEndDate == null) {
      return i18n.t(KEYS.oedgvMembership.not_a_mamber).toString();
    } else {
      const endDateFormatted = CustomFilters.date(
        this.oedgvMembershipEndDate || new Date()
      );
      if (this.isActiveOedgvMember) {
        return `${i18n
          .t(KEYS.oedgvMembership.active_till)
          .toString()} ${endDateFormatted}`;
      } else {
        return `${i18n
          .t(KEYS.oedgvMembership.ended)
          .toString()} ${endDateFormatted}`;
      }
    }
  }
}
