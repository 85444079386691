


































import Vue from "vue";
import Component from "vue-class-component";
import { KEYS } from "@/plugins/i18n";
import UserAuthDto from "@/dtos/UserAuthDto";
import { Auth } from "@/store/namespaces";
import { Route } from "vue-router";
import PlayerPaidExcelColumnSelectionComponent from "@/components/PlayerPaidExcelColumnSelectionComponent.vue";

@Component({
  components: { PlayerPaidExcelColumnSelectionComponent },
})
export default class CreateDachverbandUserComponent extends Vue {
  private sLogin: string = this.$t(KEYS.common.login).toString();
  private sPasswordRequired: string = this.$t(
    KEYS.users.passwordRequired
  ).toString();
  private sUsernameRequired: string = this.$t(
    KEYS.users.usernameRequired
  ).toString();

  private l_Username: string = this.$t(KEYS.users.username).toString();
  private l_Password: string = this.$t(KEYS.users.password).toString();

  isValid = false;

  private showPassword = false;

  @Auth.Action
  private login!: (userAuthDto: UserAuthDto) => Promise<Route>;

  private errorMessage: string | null = null;

  private authDto: UserAuthDto = {
    password: null,
    username: null,
  };

  private async submit(): Promise<void> {
    try {
      await this.login(this.authDto);
    } catch (e) {
      this.errorMessage = e.message;
    }
  }
}
