




















import { Vue, Component, Emit } from "vue-property-decorator";
import ExcelColumnSelectionCheckbox from "@/components/ExcelColumnSelectionCheckbox.vue";
import { KEYS } from "@/plugins/i18n";
import { ExcelColumDefinition } from "@/dtos/CheckPaymentStatusRequestDto";

@Component({
  components: { ExcelColumnSelectionCheckbox },
})
export default class PaymentsUploadColumnChooseComponent extends Vue {
  public readonly sColumns = this.$t(KEYS.common.columns).toString();
  public readonly sOedgvNo = this.$t(KEYS.players.playerNumber).toString();
  public readonly sHasPaid = this.$t(KEYS.common.hasPaid).toString();
  public readonly sOk = this.$t(KEYS.common.ok).toString();

  public result: ExcelColumDefinition = new ExcelColumDefinition();

  @Emit("on-apply")
  public applyClicked(): ExcelColumDefinition {
    const tempResult = this.result;
    this.result = new ExcelColumDefinition();
    return tempResult;
  }
}
