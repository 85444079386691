import Vue, { VueConstructor } from "vue";
import Vuetify from "vuetify/lib/framework";
import moment from "moment/moment";
import i18n from "@/plugins/i18n";

Vue.use(Vuetify);

export class CustomFilters {
  public static playerNumber(integer: number): string {
    return integer.toString().padStart(5, "0");
  }

  public static date(date: Date): string {
    moment.locale(i18n.locale);
    return moment(date).format("DD.MM.yyyy");
  }

  public static seasonStart(date: Date): string {
    moment.locale(i18n.locale);
    return moment(date).format("MMM yyyy");
  }
}

export default {
  install(Vue: VueConstructor): void {
    Vue.filter("playerNumber", CustomFilters.playerNumber);
  },
};
