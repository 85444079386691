<template>
  <v-app>
    <navbar></navbar>
    <v-main>
      <v-container>
        <router-view style="margin-top: 1em" />
        <ChangePasswordComponent />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Vue from "vue";
import Component from "vue-class-component";
import Navbar from "@/components/Navbar";
import ChangePasswordComponent from "@/components/ChangePasswordComponent";

document.title = "Discgolf Player Management";

@Component({
  components: { ChangePasswordComponent, Navbar },
})
export default class App extends Vue {}
</script>
<style lang="scss">
.errorLabel {
  color: var(--v-error-base);
}

.v-card__title {
  color: var(--v-primary-base);
}

.v-list {
  max-height: 39em;
  overflow: auto;
}

.v-text-field {
  min-width: 10em;
}

.v-list-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

th {
  color: var(--v-primary-base) !important;
}

.highlighted {
  background-color: #ffffe0;
}
</style>
