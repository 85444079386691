import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import PaymentDto from "@/dtos/PaymentDto";

@Module({ namespaced: true })
export class PaymentsDetailsDialogModule extends VuexModule {
  private _dto: PaymentDto | null = null;

  public get isPaymentDetailsDialogShowing(): boolean {
    return this._dto != null;
  }

  public get paymentDetailsDialogDto(): PaymentDto | null {
    return this._dto;
  }

  @Mutation
  public showPaymentDetailsDialog(dto: PaymentDto | null): void {
    this._dto = dto;
  }

  @Mutation
  public hidePaymentDetailsDialog(): void {
    this._dto = null;
  }
}
