import { settingsService } from "@/rest/settings.service";

export interface PaymentDto {
  id: string | null;
  paymentDate: Date;
  periodStart: Date;
  periodEnd: Date;
  playerId: string | null;
}

export default class PaymentDtoClass implements PaymentDto {
  public id: string | null = null;
  public paymentDate: Date;
  public playerId: string | null = null;

  private constructor() {
    const now = new Date();
    this.paymentDate = now;
    this._periodEnd = now;
    this._periodStart = now;
  }

  private _periodStart: Date;

  public get periodStart(): Date {
    return this._periodStart;
  }

  public set periodStart(val: Date) {
    this._periodStart = new Date(val.setHours(12));
    this._periodEnd = new Date(
      PaymentDtoClass.getSeasonEndFromStart(this._periodStart).setHours(12)
    );
  }

  private _periodEnd: Date;

  public get periodEnd(): Date {
    return this._periodEnd;
  }

  public static async defaultPaymentDto(): Promise<PaymentDto> {
    const res = new PaymentDtoClass();
    const dates = await this.getSeasonStartEndFromPaymentDate(res.paymentDate);
    res._periodStart = dates[1];
    res._periodEnd = dates[2];

    return res;
  }

  private static getSeasonEndFromStart(seasonStart: Date): Date {
    const seasonEnd = new Date(seasonStart);
    seasonEnd.setFullYear(seasonEnd.getFullYear() + 1);
    seasonEnd.setDate(seasonEnd.getDate() - 1);

    return seasonEnd;
  }

  private static async getSeasonStartEndFromPaymentDate(
    paymentDate: Date
  ): Promise<[paymentDate: Date, seasonStart: Date, seasonEdn: Date]> {
    const _paymentDate = new Date(paymentDate);
    _paymentDate.setHours(0, 0, 0, 0);

    const settings = await settingsService.get();
    const settingsSeasonStart = new Date(settings.seasonStart);

    settingsSeasonStart.setFullYear(_paymentDate.getFullYear());

    const seasonStartYear =
      settingsSeasonStart <= _paymentDate
        ? _paymentDate.getFullYear()
        : _paymentDate.getFullYear() - 1;

    let seasonStart = new Date(
      seasonStartYear,
      settingsSeasonStart.getMonth(),
      settingsSeasonStart.getDate()
    );
    seasonStart = new Date(seasonStart.setHours(12));

    let seasonEnd = await this.getSeasonEndFromStart(seasonStart);
    seasonEnd = new Date(seasonEnd.setHours(12));

    return [_paymentDate, seasonStart, seasonEnd];
  }

  public async setDefaultDatesFromPaymentDate(
    paymentDate: Date
  ): Promise<void> {
    this.paymentDate = paymentDate;
    const dates = await PaymentDtoClass.getSeasonStartEndFromPaymentDate(
      paymentDate
    );
    this._periodStart = dates[1];
    this._periodEnd = dates[2];
  }
}
