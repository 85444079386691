import * as XLSX from "xlsx";
import moment from "moment/moment";

export class ExcelUtils {
  public static readExcelFile(
    files: FileList,
    afterRead: (jsonResult: (string | number)[][]) => void
  ): void {
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e.target) {
        return;
      }
      const bstr = e.target.result;
      const workbook = XLSX.read(bstr, { type: "binary" });
      const excelData = XLSX.utils.sheet_to_json<(string | number)[]>(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
      );
      afterRead(excelData);
    };

    reader.readAsBinaryString(file);
  }

  public static exportExcel(
    data: { [header: string]: string }[],
    fileName?: string
  ): void {
    const ws = XLSX.utils.json_to_sheet(data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(
      wb,
      (fileName || "Export") + `_${new Date().toLocaleDateString()}.xlsx`
    );
  }

  public static GetDateValueOrNull(colValue?: string | number): Date | null {
    try {
      const momentDate = moment(colValue?.toString(), "D.M.YYYY").utcOffset(
        0,
        true
      );
      if (momentDate.isValid()) {
        return momentDate.toDate();
      } else if (typeof colValue === "number") {
        return new Date(0, 0, colValue);
      }

      return null;
    } catch {
      return null;
    }
  }
}
