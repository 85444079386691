
























































import Component from "vue-class-component";
import Vue from "vue";
import { KEYS } from "@/plugins/i18n";
import { clubsService } from "@/rest/clubs.service";
import { Emit, Watch } from "vue-property-decorator";
import ClubDetailsComponent from "@/components/ClubDetailsComponent.vue";
import { ClubDetails } from "@/store/namespaces";
import { ShowDialogData } from "@/store/ClubDialog.module";
import ClubDto from "@/dtos/ClubDto";
import AreYouSureDialogComponent from "@/components/AreYouSureDialogComponent.vue";

interface ClubListDataItem {
  isVisible: boolean;
  clubDto: ClubDto;
}

@Component({
  components: { AreYouSureDialogComponent, ClubDetailsComponent },
})
export default class ClubListComponent extends Vue {
  private data: ClubListDataItem[] = [];
  private searchText = "";
  private errorText = "";
  private showErrorSnackbar = false;

  private sSearch = this.$t(KEYS.common.search);
  private heading = this.$t(KEYS.common.clubs).toString();
  private showConfirmDeleteClubDialog = false;

  async created(): Promise<void> {
    await this.loadData();
  }

  @ClubDetails.Mutation
  private show!: (data: ShowDialogData) => void;

  @ClubDetails.Getter
  private isShowing!: boolean;

  private sDeleteClub = this.$t(KEYS.clubs.deleteClub).toString();
  private clubToDelete: ClubDto | null = null;

  private cloneDto(dto: ClubDto): ClubDto {
    return Object.assign({}, dto);
  }

  @Watch("searchText")
  searchTextChange(newValue: string, oldValue: string): void {
    const oldNormalized = oldValue.trim().toLocaleLowerCase();
    const newNormalized = newValue.trim().toLocaleLowerCase();
    if (oldNormalized !== newNormalized) {
      for (let club of this.data) {
        club.isVisible =
          club.clubDto.name?.toLowerCase().startsWith(newNormalized) || false;
      }
    }
  }

  private async loadData(): Promise<void> {
    const dtoList: ClubDto[] = await clubsService.getAll();
    const searchLower = this.searchText?.toLowerCase() || "";
    this.data = dtoList.map<ClubListDataItem>((dto) => ({
      clubDto: dto,
      isVisible: dto.name?.toLowerCase().startsWith(searchLower) || false,
    }));
  }

  private createClick() {
    this.show({ clubDto: null, isShowing: true });
  }

  private deleteClubClick(club: ClubDto) {
    this.clubToDelete = club;
    this.showConfirmDeleteClubDialog = true;
  }

  private async deleteClubConfirmed() {
    try {
      await clubsService.delete(this.clubToDelete);
      await this.loadData();
    } catch (e) {
      this.errorText = e.message;
      this.showErrorSnackbar = true;
    } finally {
      this.showConfirmDeleteClubDialog = false;
    }
  }

  @Watch("isShowing")
  private async onIsShowingChange(newValue: boolean) {
    if (!newValue) {
      await this.loadData();
    }
  }

  @Emit()
  private rowClicked(club: ClubDto): ClubDto {
    return Object.assign({}, club);
  }
}
