import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import ClubDto from "@/dtos/ClubDto";

@Module({ namespaced: true })
export class PlayersListDialogModule extends VuexModule {
  private _isShowing = false;
  private _club: ClubDto | null = null;

  @Mutation
  public showPlayersListDialog(club: ClubDto): void {
    this._isShowing = true;
    this._club = club;
  }

  public get isPlayersListDialogShowing(): boolean {
    return this._isShowing;
  }

  public get playersListDialogClub(): ClubDto | null {
    return this._club;
  }

  @Mutation
  public hidePlayersListDialog(): void {
    this._isShowing = false;
    this._club = null;
  }
}
