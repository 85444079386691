









import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component
export default class ExcelColumnSelectionCheckbox extends Vue {
  private readonly letters = "-ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  @Prop()
  private label!: string;

  @Prop()
  private value!: string;
}
