import i18n, { KEYS } from "@/plugins/i18n";
const Genders = {
  MALE: {
    value: 0,
    text: i18n.t(KEYS.genders.male).toString(),
  },
  FEMALE: {
    value: 1,
    text: i18n.t(KEYS.genders.female).toString(),
  },
  DIVERSE: {
    value: 2,
    text: i18n.t(KEYS.genders.diverse).toString(),
  },
};

export interface SelectItem {
  text: string;
  value: number;
}

export function genderSelectItems(): Array<SelectItem> {
  const result = new Array<SelectItem>();
  for (const i in Genders) {
    const gender = Genders[i as keyof typeof Genders];
    result.push({
      text: gender.text,
      value: gender.value,
    });
  }
  return result;
}

export function GetGenderTextByNumberValue(numberValue: number): string {
  for (const i in Genders) {
    const gender = Genders[i as keyof typeof Genders];
    if (gender.value == numberValue) {
      return gender.text;
    }
  }

  throw new Error(`Gender with number value ${numberValue} not found!`);
}
export default Genders;
