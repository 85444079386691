






import { Component, Prop, Vue } from "vue-property-decorator";
import { PaymentsDetailsDialog } from "@/store/namespaces";
import PaymentDetailsComponent from "@/components/PaymentDetailsComponent.vue";

@Component({
  components: { PaymentDetailsComponent },
})
export default class PaymentDetailsDialogComponent extends Vue {
  @PaymentsDetailsDialog.Getter
  private isPaymentDetailsDialogShowing!: boolean;
  @PaymentsDetailsDialog.Mutation
  private hidePaymentDetailsDialog!: () => void;

  @Prop()
  private readonly maxWidth!: string;

  private get isShowing(): boolean {
    return this.isPaymentDetailsDialogShowing;
  }

  private set isShowing(newVal: boolean) {
    if (!newVal) {
      this.hidePaymentDetailsDialog();
    }
  }
}
