import SettingsDto from "@/dtos/SettingsDto";
import axios from "axios";

class SettingsService {
  private readonly path = "api/settings";
  private readonly seasonStartPath = `${this.path}/seasonStart`;

  public async get(): Promise<SettingsDto> {
    const response = await axios.get<SettingsDto>(this.path);
    return response.data;
  }

  public async setSeasonBegin(date: Date): Promise<void> {
    await axios.post(this.seasonStartPath, date);
  }
}

export const settingsService = new SettingsService();
