import { namespace } from "vuex-class";

export const Auth = namespace("auth");
export const ClubDetails = namespace("clubDetails");
export const PlayerDetails = namespace("playerDetails");
export const UserDetails = namespace("userDetails");
export const ChangePasswordDialog = namespace("changePasswordDialog");
export const PlayersListDialog = namespace("playersListDialog");
export const PaymentsListDialog = namespace("paymentsListDialog");
export const PaymentsDetailsDialog = namespace("paymentsDetailsDialog");
