import axios from "axios";
import PlayerDto from "@/dtos/PlayerDto";
import GetPlayerDto from "@/dtos/GetPlayerDto";
import CheckPaymentStatusResponseDto from "@/dtos/CheckPaymentStatusResponseDto";
import CheckPaymentStatusRequestDto from "@/dtos/CheckPaymentStatusRequestDto";
import { MultiCreatePlayerRequestDto } from "@/dtos/MultiCreatePlayerRequestDto";
import { MultiCreatePlayerResultDto } from "@/dtos/MultiCreatePlayerResultDto";
import { handleErrorCodeException } from "@/rest/ErrorCodes";

class PlayersService {
  private readonly path = "api/players";
  private readonly paymentStatusPath = this.path + "/paymentstatus";
  private readonly createMultiplePath = this.path + "/createmulti";

  public async createPlayer(dto: PlayerDto): Promise<PlayerDto | null> {
    try {
      const response = await axios.put<PlayerDto>(this.path, dto);
      return response.data;
    } catch (e) {
      throw handleErrorCodeException(e);
    }
  }

  public async createMultiple(
    dtos: MultiCreatePlayerRequestDto[]
  ): Promise<MultiCreatePlayerResultDto[]> {
    try {
      const response = await axios.put<MultiCreatePlayerResultDto[]>(
        this.createMultiplePath,
        dtos
      );
      return response.data;
    } catch (e) {
      throw handleErrorCodeException(e);
    }
  }

  public async getAll(): Promise<GetPlayerDto[]> {
    const response = await axios.get<GetPlayerDto[]>(this.path);
    return response.data.map((x) => Object.assign(new GetPlayerDto(), x));
  }

  public async update(dto: PlayerDto) {
    try {
      await axios.post(this.path, dto);
    } catch (e) {
      throw handleErrorCodeException(e);
    }
  }
  public async getPaymentStatus(
    requestDto: CheckPaymentStatusRequestDto[]
  ): Promise<CheckPaymentStatusResponseDto[]> {
    const response = await axios.post<CheckPaymentStatusResponseDto[]>(
      this.paymentStatusPath,
      requestDto
    );
    return response.data;
  }

  public async delete(player: PlayerDto | null) {
    if (player == null) {
      return;
    }

    await axios.delete(`${this.path}/${player.id}`);
  }

  public async getPlayerByPlayerNumber(
    playerNumber: string
  ): Promise<GetPlayerDto> {
    try {
      const response = await axios.get<GetPlayerDto>(
        `${this.path}/${playerNumber}`
      );

      const result = new GetPlayerDto();
      Object.assign(result, response.data);

      return result;
    } catch (e) {
      throw handleErrorCodeException(e);
    }
  }
}

export const playersService = new PlayersService();
