








import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "@/components/HelloWorld.vue";
import LoginComponent from "@/components/LoginComponent.vue"; // @ is an alias to /src

@Component({
  components: {
    LoginComponent,
    HelloWorld,
  },
})
export default class LoginView extends Vue {}
