import { AxiosError } from "axios";
import i18n, { KEYS } from "@/plugins/i18n";

export enum ErrorCodes {
  Email_exists = 0,
  Username_exists = 1,
  Club_name_exists = 2,
  Player_exists = 3,
  General_error = 99999999,
  Oedgv_number_exists = 4,
  Pdga_exists = 5,
  Player_not_found = 6,
  Payment_exists = 7,
}

export function translateErrorCode(errorCode: ErrorCodes): string {
  switch (errorCode) {
    case ErrorCodes.Email_exists:
      return i18n.t(KEYS.error.email_already_exists).toString();
    case ErrorCodes.Username_exists:
      return i18n.t(KEYS.error.username_already_exists).toString();
    case ErrorCodes.Club_name_exists:
      return i18n.t(KEYS.error.club_name_exists).toString();
    case ErrorCodes.Player_exists:
      return i18n.t(KEYS.error.player_exists).toString();
    case ErrorCodes.General_error:
    case ErrorCodes.Oedgv_number_exists:
      return i18n.t(KEYS.error.oedgv_number_exists).toString();
    case ErrorCodes.Pdga_exists:
      return i18n.t(KEYS.error.pdga_exists).toString();
    case ErrorCodes.Player_not_found:
      return i18n.t(KEYS.error.player_not_found).toString();
    case ErrorCodes.Payment_exists:
      return i18n.t(KEYS.error.already_payed_in_that_period).toString();
    default:
      return i18n.t(KEYS.error.general_error).toString();
  }
}
export function handleErrorCodeException(e: Error): Error | undefined {
  const errorCode = (e as AxiosError)?.response?.data as ErrorCodes;
  if (errorCode) {
    return new Error(translateErrorCode(errorCode));
  }

  return new Error(translateErrorCode(ErrorCodes.General_error));
}
