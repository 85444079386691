import { NavigationGuard } from "vue-router";
import { usersService } from "@/rest/users.service";
import store from "@/store";

interface INavigationGuards {
  onlyDachverband: NavigationGuard;
  requiresLogin: NavigationGuard;
  ensureInitialized: NavigationGuard;
}

export const navigationGuards: INavigationGuards = {
  onlyDachverband: async (to, from, next) => {
    const isDachverband = store.getters["auth/isDachverbandUser"];
    if (isDachverband) {
      next();
    } else {
      next({ path: "/" });
    }
  },
  requiresLogin: async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters["auth/isLoggedIn"]) {
        next({
          path: "/login",
        });
      } else {
        next();
      }
    } else {
      next(); // make sure to always call next()!
    }
  },
  ensureInitialized: async (to, from, next) => {
    const isInitialized = await usersService.isInitialized();
    if (isInitialized) {
      next();
    } else {
      next({ name: "FirstSetup" });
    }
  },
};
