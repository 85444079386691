
















import Component from "vue-class-component";
import Vue from "vue";
import { Emit, Prop } from "vue-property-decorator";
import { KEYS } from "@/plugins/i18n";

@Component
export default class AreYouSureDialogComponent extends Vue {
  @Prop({ default: "" })
  private header!: string;

  private sAreYouSure = this.$t(KEYS.common.are_you_sure).toString();
  private sCancel = this.$t(KEYS.common.cancel).toString();
  private sOk = this.$t(KEYS.common.ok).toString();

  @Emit()
  private confirmed() {
    return;
  }

  @Emit()
  private canceled() {
    return;
  }
}
