






















































































import Component from "vue-class-component";
import Vue from "vue";
import { KEYS } from "@/plugins/i18n";
import { ClubDetails } from "@/store/namespaces";
import { clubsService } from "@/rest/clubs.service";
import { Ref, Watch } from "vue-property-decorator";
import ClubDto from "@/dtos/ClubDto";
import { FederalStateItem, getFederalStateItems } from "@/dtos/FederateStates";

@Component
export default class ClubDetailsComponent extends Vue {
  private isValid = false;
  private l_name = this.$t(KEYS.common.name).toString();
  private l_username = this.$t(KEYS.users.username).toString();
  private l_password: string = this.$t(KEYS.users.password).toString();
  private l_PasswordRepeat: string = this.$t(
    KEYS.users.password_repeat
  ).toString();
  private l_email = this.$t(KEYS.users.email).toString();
  private l_city = this.$t(KEYS.common.city).toString();
  l_federalState = this.$t(KEYS.common.federalState).toString();
  private s_save = this.$t(KEYS.common.save).toString();
  private s_cancel = this.$t(KEYS.common.cancel).toString();
  private s_valueRequired = this.$t(KEYS.error.value_required).toString();
  private sPasswordRequired: string = this.$t(
    KEYS.users.passwordRequired
  ).toString();
  private sPasswordsDontMatch = this.$t(
    KEYS.users.passwords_dont_match
  ).toString();

  private errorMessage: string | null = null;
  private passwordRepeat = "";
  private federalStateItems = getFederalStateItems();

  private getFederalStateItemValue(item: FederalStateItem) {
    return item.value;
  }

  private getFederalStateItemText(item: FederalStateItem) {
    return item.text;
  }

  @ClubDetails.Getter
  private isShowing!: boolean;
  @ClubDetails.Mutation
  private hide!: () => void;

  @Ref()
  private form!: HTMLFormElement;

  @ClubDetails.Getter
  private clubDto!: ClubDto;

  get _isShowing(): boolean {
    return this.isShowing;
  }

  set _isShowing(val: boolean) {
    if (!val) {
      this.hide();
    }
  }

  private get isCreate(): boolean {
    return !this.clubDto?.id;
  }

  get heading(): string {
    if (this.isCreate) {
      return this.$t(KEYS.headings.create_club).toString();
    } else {
      return this.$t(KEYS.headings.edit_club).toString();
    }
  }

  async submit(): Promise<void> {
    this.errorMessage = null;
    try {
      if (this.isCreate) {
        await clubsService.createClub(this.clubDto);
      } else {
        await clubsService.update(this.clubDto);
      }
      this.hide();
    } catch (e) {
      this.errorMessage = e.message;
    }
  }

  @Watch("isShowing")
  onIsShowingChange(newVal: boolean): void {
    if (newVal) {
      this.errorMessage = "";
      if (this.isCreate) {
        this.form.reset();
      }
    }
  }
}
