





















































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import AreYouSureDialogComponent from "@/components/AreYouSureDialogComponent.vue";
import PlayerDto from "@/dtos/PlayerDto";

const TableComponentProps = Vue.extend({
  props: {
    headers: [],
    items: [],
  },
});

@Component({
  components: { AreYouSureDialogComponent },
})
export default class TableComponent extends TableComponentProps {
  @Prop({ default: false })
  private showPaymentsAction!: boolean;

  @Prop({ default: false })
  private showEditAction!: boolean;

  @Prop({ default: "" })
  private deleteTitle!: string;

  private showAreYouSureDeleteDialog = false;
  private playerDtoToDelete: PlayerDto | null = null;

  @Emit()
  onEdit(item: unknown): unknown {
    return item;
  }

  @Emit()
  onShowPayments(item: unknown): unknown {
    return item;
  }

  deleteClick(player: PlayerDto): void {
    this.playerDtoToDelete = player;
    this.showAreYouSureDeleteDialog = true;
  }

  @Emit()
  onDelete(): PlayerDto | null {
    this.showAreYouSureDeleteDialog = false;
    return this.playerDtoToDelete;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private headersWithCustomFormatter(headers: [object]) {
    // eslint-disable-next-line no-prototype-builtins
    return headers.filter((header) => header.hasOwnProperty("formatter"));
  }
}
